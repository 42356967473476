
import { mapGetters } from 'vuex';
import StoryblokClient from 'storyblok-js-client';
import { CMSService } from '~/services/storyBlok/cmsService';
import MobileFooterLinkSection from './MobileFooterLinkSection.vue';

export default {
  name: 'TopFooterLinks',
  components: {
    MobileFooterLinkSection,
  },
  data() {
    return {
      footerData: {},
      storyRespId: '',
      isActive: false,
    };
  },
  methods: {
    getLinkName(name) {
      return `${this.$options.name}_${name}`;
    },
    toggleSection(title) {
      this.isActive = title;
    },
  },
  computed: {
    footerContent() {
      const Storyblok = new StoryblokClient({ accessToken: process.env.NUXT_ENV_STORYBLOK });
      const hoursCopy = this.footerData[0]?.footer[0]?.hoursCopy;

      return hoursCopy ? Storyblok.richTextResolver.render(hoursCopy) : '';
    },
    editableFooterContent() {
      return this.footerData[0]?.footer[0] ?? '';
    },
    shopCTA() {
      return 'Shop Package';
    },
    footerLinks() {
      return [
        {
          title: 'Shop',
          links: [
            {
              cta: this.shopCTA,
              elementName: 'NuxtLink',
              bindings: {
                to: '/shop/products/fightcamp',
              },
            },
            {
              cta: 'Accessories',
              elementName: 'NuxtLink',
              bindings: {
                to: '/shop/accessories',
              },
            },
            {
              cta: 'Apparel',
              elementName: 'a',
              bindings: {
                href: 'https://apparel.joinfightcamp.com/',
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            },
            {
              cta: this.$store.getters.exp_033_isConsoleOnlyVariant ? 'Already Own a Bag?' : 'First Generation Trackers',
              elementName: 'NuxtLink',
              bindings: {
                to: this.$store.getters.exp_033_isConsoleOnlyVariant ? '/shop/products/fightcamp-console' : '/shop/products/fightcamp-trackers',
              },
            },
          ],
        },
        {
          title: 'Explore',
          links: [
            {
              cta: 'Why FightCamp',
              elementName: 'NuxtLink',
              bindings: {
                to: '/why-choose-fightcamp',
              },
            },
            {
              cta: 'How it Works',
              elementName: 'NuxtLink',
              bindings: {
                to: '/how-it-works',
              },
            },
            {
              cta: 'Careers',
              elementName: 'NuxtLink',
              bindings: {
                to: '/careers',
              },
            },
            {
              cta: 'Press',
              elementName: 'NuxtLink',
              bindings: {
                to: '/press',
              },
            },
          ],
        },
        {
          title: 'Support',
          links: [
            {
              cta: 'Manage Account',
              elementName: 'a',
              bindings: {
                href: 'https://account.joinfightcamp.com',
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            },
            {
              cta: 'Switch to Annual Billing',
              elementName: 'a',
              bindings: {
                href: 'https://account.joinfightcamp.com',
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            },
            {
              cta: 'User Manual',
              elementName: 'a',
              bindings: {
                href: 'https://joinfightcamp.com/user-manual',
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            },
            {
              cta: 'Help Center',
              elementName: 'a',
              bindings: {
                href: 'https://fightcamp.zendesk.com/hc/en-us',
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            },
            {
              cta: 'Return Policy',
              elementName: 'NuxtLink',
              bindings: {
                to: '/return-policy',
              },
            },
          ],
        },
        {
          title: 'Contact',
        },
      ];
    },
    ...mapGetters([
      'isInStoryblok',
    ]),
  },
  async fetch() {
    const storyBlok = new CMSService({ cmsService: this.$storyapi });
    const { data } = await storyBlok.get('data/footer');

    this.footerData = data.story?.content?.body || [];
    this.storyRespId = data?.story?.id;
  },
  mounted() {
    if (this.isInStoryblok) {
      this.$storybridge(
        () => {
          const { StoryblokBridge } = window;
          if (StoryblokBridge != null) {
            const storyblokInstance = new StoryblokBridge();
            storyblokInstance.on(['input', 'published', 'change'], (event) => {
              if (event.action === 'input') {
                if (event.story.id === this.storyRespId) {
                  this.footerData = event.story.content.body;
                }
              } else {
                window.location.reload();
              }
            });
          }
        },
        (error) => {
          console.error('ERROR', error);
        },
      );
    }
  },
};
