
import {
  defineComponent, ref,
} from '@vue/composition-api';

export default defineComponent({
  name: 'MobileFooterLinkSection',
  props: {
    section: {
      type: Object,
      required: true,
    },
    footerContent: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isActive = ref(false);

    function toggleActive() {
      isActive.value = !isActive.value;
    }

    return {
      isActive,
      toggleActive,
    };
  },
});
